import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  toto = "Damansah | Empowering African Business Owners";
  
  constructor (public translate: TranslateService ){
    
    translate.addLangs(['Français', 'English']);
    translate.setDefaultLang('Français') ;
    const browserLang = translate.getBrowserLang(); //obtention de la langue du browser
    //translate.use(browserLang.match(/English|Français/) ? browserLang: 'English' ); //utilisation de langue du browser si elle correspond a l'un de ceux qu'on a
  }

  ngOnInit() {
  }

}
