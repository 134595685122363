import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component' ;
import { AboutComponent } from './about/about.component' ;
import { ContactComponent } from './contact/contact.component' ;
import { ClientComponent} from './client/client.component';
import { SmartshopComponent} from './smartshop/smartshop.component';
import { ChiefboardComponent } from './chiefboard/chiefboard.component';
import { GetstartComponent } from './getstart/getstart.component';
import { TermsComponent } from './terms/terms.component';
import { PolicyComponent } from './policy/policy.component';
import { BlogComponent} from './blog/blog.component';
import { TutorielComponent} from './tutoriel/tutoriel.component';
import { WaribonComponent } from './waribon/waribon.component';
import { MarketplaceComponent } from './marketplace/marketplace.component';
import { FaqComponent } from './faq/faq.component';

const routes: Routes = [
  {path: '', redirectTo: '/', pathMatch: 'full'},
  {path: '', component: HomeComponent, data: {title: 'Home'}}, 
  {path: 'about', component: AboutComponent},
  {path: 'contact-us', component: ContactComponent},
  {path: 'client', component: ClientComponent},
  {path: 'smartshop', component: SmartshopComponent},
  {path: 'pro', component: ChiefboardComponent},
  {path: 'getstart', component: GetstartComponent},
  {path: 'terms-and-conditions', component: TermsComponent},
  {path: 'privacy-policy', component: PolicyComponent},
  {path: 'blog', component: BlogComponent},
  {path: 'tutoriel', component: TutorielComponent},
  {path: 'waribon', component: WaribonComponent},
  {path: 'marketplace', component: MarketplaceComponent},
  {path: 'faq', component:FaqComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


