import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { ClientComponent } from './client/client.component';
import { SmartshopComponent } from './smartshop/smartshop.component';
import { ChiefboardComponent } from './chiefboard/chiefboard.component';
import { GetstartComponent } from './getstart/getstart.component';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core'; 
import {TranslateHttpLoader} from '@ngx-translate/http-loader' ;
import {HttpClientModule, HttpClient} from '@angular/common/http';
import { TermsComponent } from './terms/terms.component';
import { PolicyComponent } from './policy/policy.component';
import { BlogComponent } from './blog/blog.component';
import { TutorielComponent } from './tutoriel/tutoriel.component';
import { WaribonComponent } from './waribon/waribon.component';
import { MarketplaceComponent } from './marketplace/marketplace.component';
import { FaqComponent } from './faq/faq.component';

export function  HttpLoaderFactory(http: HttpClient){
  return new TranslateHttpLoader(http) ;
}

@NgModule({
  declarations: [ 
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    ClientComponent,
    SmartshopComponent,
    ChiefboardComponent,
    GetstartComponent,
    TermsComponent,
    PolicyComponent,
    BlogComponent,
    TutorielComponent,
    WaribonComponent,
    MarketplaceComponent,
    FaqComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
