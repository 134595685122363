import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tutoriel',
  templateUrl: './tutoriel.component.html',
  styleUrls: ['./tutoriel.component.css']
})
export class TutorielComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
